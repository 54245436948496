import React, { createContext, useContext, useState, useCallback } from "react";

// Create a new context for a single stream
const SingleStreamContext = createContext();

// Custom hook to use the single stream context
export const useSingleStreamContext = () => useContext(SingleStreamContext);

// Context Provider component for a single stream
export const SingleStreamProvider = ({ children }) => {
    const [stream, setStream] = useState(null);
    const [cachedStreams, setCachedStreams] = useState([]);

    const fetchStreamMetadata = async (streamId) => {
        try {
            const response = await fetch(
                `/wp-json/ccl_admin/v1/get/post/meta/${streamId}`
            );
            if (!response.ok) {
                throw new Error("Failed to fetch stream metadata");
            }
            return await response.json();
        } catch (error) {
            console.error("Error fetching stream metadata:", error);
            return null;
        }
    };

    const getStreamBySlug = useCallback(
        async (streamSlug) => {
            // Check if the stream is already in cache
            const cachedStream = cachedStreams.find(
                (stream) => stream.slug === streamSlug
            );
            if (cachedStream) {
                setStream(cachedStream);
                return cachedStream;
            }

            try {
                const response = await fetch(
                    `/wp-json/wp/v2/streams?slug=${streamSlug}&_embed=true`
                );
                if (!response.ok) {
                    throw new Error("Failed to fetch stream");
                }

                const data = await response.json();
                if (data.length > 0) {
                    const fetchedStream = data[0];
                    const metadata = await fetchStreamMetadata(
                        fetchedStream.id
                    );
                    if (metadata) {
                        fetchedStream.metadata = metadata;
                    }
                    setStream(fetchedStream);
                    // Cache the fetched stream
                    setCachedStreams((prevStreams) => [
                        ...prevStreams,
                        fetchedStream,
                    ]);
                    return fetchedStream;
                } else {
                    setStream(null);
                    return null;
                }
            } catch (error) {
                console.error("Error fetching stream by slug:", error);
                setStream(null);
                return null;
            }
        },
        [cachedStreams]
    );

    return (
        <SingleStreamContext.Provider value={{ stream, getStreamBySlug }}>
            {children}
        </SingleStreamContext.Provider>
    );
};
