import React, {
    createContext,
    useContext,
    useState,
    useEffect,
    useCallback,
} from "react";

// Create a new context
const StreamContext = createContext();

// Custom hook to use the stream context
export const useStreamContext = () => useContext(StreamContext);

// Context Provider component
export const StreamProvider = ({ children }) => {
    const [streams, setStreams] = useState([]);
    const [orderedStreams, setOrderedStreams] = useState([]);
    const [recentStreams, setRecentStreams] = useState([]);
    const [streamMetadataCache, setStreamMetadataCache] = useState({});

    const fetchStreams = useCallback(async () => {
        if (streams.length === 0) {
            try {
                const response = await fetch(
                    "/wp-json/wp/v2/streams?per_page=100&page=1&_embed=true"
                );

                if (!response.ok) {
                    throw new Error("Failed to fetch streams");
                }

                const data = await response.json();

                // Filter out streams with title "test"
                const filteredStreams = data.filter(
                    (stream) => stream.title.rendered.toLowerCase() !== "test"
                );

                setStreams(filteredStreams);

                // Sort streams alphabetically by title for orderedStreams
                const sortedByTitleStreams = [...filteredStreams].sort((a, b) =>
                    a.title.rendered
                        .toLowerCase()
                        .localeCompare(b.title.rendered.toLowerCase())
                );
                setOrderedStreams(sortedByTitleStreams);

                // Sort filtered streams by date in descending order (most recent first)
                const sortedStreams = [...filteredStreams].sort(
                    (a, b) => new Date(b.date) - new Date(a.date)
                );

                // Get the last 6 created streams
                const last6Streams = sortedStreams.slice(0, 6);
                setRecentStreams(last6Streams);
            } catch (error) {
                console.error("Error fetching streams:", error);
            }
        }
    }, [streams]);

    useEffect(() => {
        fetchStreams();
    }, [fetchStreams]);

    const fetchStreamMetadata = async (streamId) => {
        try {
            const response = await fetch(
                `/wp-json/ccl_admin/v1/get/post/meta/${streamId}`
            );
            if (!response.ok) {
                throw new Error("Failed to fetch stream metadata");
            }
            return await response.json();
        } catch (error) {
            console.error("Error fetching stream metadata:", error);
            return null;
        }
    };

    const getStreamBySlug = useCallback(
        async (streamSlug) => {
            if (streams.length === 0) {
                await fetchStreams();
            }

            // Check if the stream is already in cache
            if (streamMetadataCache[streamSlug]) {
                return {
                    ...streams.find((stream) => stream.slug === streamSlug),
                    metadata: streamMetadataCache[streamSlug],
                };
            }

            const stream = streams.find((stream) => stream.slug === streamSlug);
            if (stream) {
                // If stream found, fetch metadata if not cached
                if (!stream.metadata) {
                    const metadata = await fetchStreamMetadata(stream.id);
                    if (metadata) {
                        stream.metadata = metadata;
                        // Update cache
                        setStreamMetadataCache((prevCache) => ({
                            ...prevCache,
                            [streamSlug]: metadata,
                        }));
                    }
                }
            }
            return stream;
        },
        [streams, fetchStreams, streamMetadataCache]
    );

    return (
        <StreamContext.Provider
            value={{ streams, recentStreams, orderedStreams, getStreamBySlug }}
        >
            {children}
        </StreamContext.Provider>
    );
};
